import {
  validateForms
} from '../functions/validate-forms';

const modals = document.querySelectorAll('.graph-modal');

const rules = [{
    ruleSelector: '.input-email',
    rules: [{
        rule: 'required',
        value: true,
        errorMessage: 'Заполните Email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный email'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [{
      rule: 'required',
      value: true,
      errorMessage: 'Заполните телефон'
    }]
  },
];

const rules1 = [{
    ruleSelector: '.input-email',
    rules: [{
        rule: 'required',
        value: true,
        errorMessage: 'Заполните Email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный email'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [{
      rule: 'required',
      value: true,
      errorMessage: 'Заполните телефон'
    }]
  },
];

const rules2 = [{
    ruleSelector: '.input-email',
    rules: [{
        rule: 'required',
        value: true,
        errorMessage: 'Заполните Email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный email'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [{
      rule: 'required',
      value: true,
      errorMessage: 'Заполните телефон'
    }]
  },
];

const rules3 = [{
    ruleSelector: '.input-email',
    rules: [{
        rule: 'required',
        value: true,
        errorMessage: 'Заполните Email'
      },
      {
        rule: 'email',
        value: true,
        errorMessage: 'Введите корректный email'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [{
      rule: 'required',
      value: true,
      errorMessage: 'Заполните телефон'
    }]
  },
];

const afterForm = () => {
  console.log('Произошла отправка, тут можно писать любые действия');
};

validateForms('.hero-form', rules, afterForm);
validateForms('.pres-form', rules1, afterForm);
validateForms('.plan-form', rules2, afterForm);
validateForms('.city-form', rules3, afterForm);
