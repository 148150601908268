import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

const formatSwiper = new Swiper('.format-swiper', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  loop: true,
  navigation: {
    nextEl: '.format-swiper-btn-next',
    prevEl: '.format-swiper-btn-prev',
  },
});

const resultsSwiper = new Swiper('.results-swiper', {
  autoplay: {
    delay: 20,
    disableOnInteraction: false,
  },
  slidesPerView: 'auto',
  spaceBetween: 80,
  loop: true,
  navigation: {
    nextEl: '.results-swiper-btn-next',
    prevEl: '.results-swiper-btn-prev',
  },
});


